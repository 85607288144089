<template>
  <div class="flex gap-4 justify-center">
    <div
      :class="{
        'bg-nColorOrange': background === 'orange',
        'bg-nColorDarkGray': background === 'darkgray',
      }"
      class="rounded-lg px-2 w-32 py-1 relative z-10"
    >
      <img
        :src="require('@/assets/images/timer-smile.svg')"
        class="absolute -top-2 -left-3"
        v-if="showIcons"
      />
      <h2
        ref="counterText"
        :class="{
          'text-white': textcolor === 'white',
          'text-black': background === 'black',
        }"
        class="font-ZuumeExtraBold text-4xl text-center"
      >
        Score: {{ score }}
      </h2>
    </div>
    <div
      :class="{
        'bg-nColorOrange': background === 'orange',
        'bg-nColorDarkGray': background === 'darkgray',
      }"
      class="rounded-lg w-40 px-4 py-1 relative z-10"
    >
      <img
        :src="require('@/assets/images/timer-fries.svg')"
        class="absolute -top-5 -right-5"
        v-if="showIcons"
      />
      <h2
        id="counterText"
        ref="counterText"
        :class="{
          'text-white': textcolor === 'white',
          'text-black': textcolor === 'black',
        }"
        class="font-ZuumeExtraBold text-4xl flex justify-center"
      >
        <div class="w-10 text-center">
          {{ formatTimer(displayTime, displayTime.minute) }}
        </div>
        :
        <div class="w-10 text-center">
          {{ formatTimer(displayTime, displayTime.sec) }}
        </div>
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "Counter",
  props: {
    score: {
      required: true,
    },
    displayTime: {
      required: true,
    },
    background: {
      required: false,
      default: () => "orange",
    },
    textcolor: {
      required: false,
      default: () => "black",
    },
    showIcons: {
      required: false,
      default: () => true,
    },
  },
  methods: {
    formatTimer(displayTime, time) {
      if (displayTime.minute == 0 && displayTime.sec <= 10) {
        document
          .getElementById("counterText")
          .classList.remove("text-nColorYellow");
        document
          .getElementById("counterText")
          .classList.add("text-nColorDarkRed");
      }
      return time > 9 ? "" + time : "0" + time;
    },
  },
};
</script>

<style scoped></style>